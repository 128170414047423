<script setup lang="ts">
import 'vue-tools/ts-tools/ts/ts-helpers/array.ts';

import { watch } from 'vue';
import { Link, Meta, createHead } from '@unhead/vue';
import { apolloLoaded } from '@/apollo';
import { isLoading, isWaiting } from '@/states/_globals';
import { useRoute } from 'vue-router';
import { getContentSrc } from '@/utils/utils';
import Loader from '@/components/Loader/Loader.vue';
import Zendesk from '@/components/Zendesk.vue';
import { useSiteStore } from '@/stores/site.ts';
import { useUserStore } from '@/stores/user.ts';
import { storeToRefs } from 'pinia';
import { useTranslation } from 'i18next-vue';
import { language } from '@/plugins/i18next.ts';
import { areStoresLoading } from '@/utils/pinia.ts';
import { RouterView } from 'vue-router';

const route = useRoute(),
    siteStore = useSiteStore(),
    userStore = useUserStore(),
    { t } = useTranslation(),
    head = createHead(); // https://unhead.unjs.io/api/core/push

watch(
    apolloLoaded,
    (loaded) => {
        if (loaded) {
            const metaData: Meta[] = [
                {
                    name: 'description',
                    content: () => siteStore.site?.options?.description,
                },
                {
                    name: 'mobile-web-app-capable',
                    content: 'yes',
                },
                {
                    name: 'apple-mobile-web-app-capable',
                    content: 'yes',
                },
            ];

            const linkData: Link[] = [
                {
                    rel: 'canonical',
                    href: () => (siteStore.site?.https ? 'https://' : 'http://') + siteStore.site?.domain,
                },
            ];
            const entry = head.push({ meta: metaData, link: linkData });

            watch(storeToRefs(siteStore).site, (site) => {
                // * Set the site icons
                if (site?.icon) {
                    const iconLinkData: Link[] = [];
                    for (const rel of ['icon', 'apple-touch-icon', 'shortcut icon', 'mask-icon']) {
                        iconLinkData.push({
                            rel,
                            href: () => (site?.icon ? getContentSrc(site?.icon) : 'favicon.ico'),
                            type: 'image/x-icon',
                            sizes: 'any',
                        });
                    }
                    entry.patch({ title: site.name, meta: metaData, link: [...linkData, ...iconLinkData] });
                }

                if (site?.options) {
                    // * Set the site colors
                    for (const [key, color] of Object.entries(site.options?.style?.theme?.colors ?? {})) {
                        if (typeof color === 'object' && color !== null) {
                            for (const [colorName, colorValue] of Object.entries(color)) {
                                document.documentElement.style.setProperty(`--${key}${colorName}`, String(colorValue));
                            }
                        }
                    }
                }
            });

            watch(
                language,
                () => {
                    /*
                     * if you change the default text here, don't forget to change it in the didomi custom css
                     * (https://console.didomi.io/darylsoftware/consent-notices/Jc2pdAwF/custom-css)
                     */
                    document.documentElement.style.setProperty('--didomi-deny-text', `"${t("N'accepter que les cookies techniques", { defaultValue: "N'accepter que les cookies techniques" })}"`);
                },
                { immediate: true }
            );

            watch(
                [isLoading, isWaiting, areStoresLoading, apolloLoaded],
                () => {
                    if (isLoading.value || isWaiting.value || areStoresLoading.value || !apolloLoaded.value) {
                        document.body.classList.add('didomi-hide');
                    } else {
                        document.body.classList.remove('didomi-hide');
                    }
                },
                { immediate: true }
            );
        }
    },
    { immediate: true }
);
</script>

<template>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <template v-if="apolloLoaded">
        <Zendesk v-if="!userStore.user" />
        <div v-if="siteStore?.site || route.path.includes('/x/location/') || route.path.includes('/x/internal/') || route.path.includes('/global') || route.path.includes('/dfiles')">
            <RouterView />
        </div>
    </template>
    <Loader data-test-id="loader" :forceLoading="!apolloLoaded" />
</template>

<style lang="scss">
body {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
}
</style>
