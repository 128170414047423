import { computed, reactive } from 'vue';
import { confirmEmailRequired, datingToken, globalHost, hasLocalStorageKey, keyClientID, keyEnv, keyLayer, keyOrigin, keyToken, message, stateCurrentOrder, token } from '@/states/_globals.ts';
import { GetCurrentOrderDocument, GetCurrentOrderQuery, RandomUsernamesDocument, RandomUsernamesQuery, UserDocument, UserQuery } from '@/generated/gql.coaching.ts';
import { router } from '@/router.ts';
import { logout } from '@/services/user.ts';
import { getDataDeepCopy, resetStore, StoreData, waitTime } from '@/utils/pinia.ts';
import { defineStore } from 'pinia';
import { buildUrlWithConsent } from 'vue-tools/src/didomi/didomi.ts';
import { addParamToUrl } from '@/utils/utils.ts';
import { getApolloClient } from '@/apollo.ts';
import i18next from 'i18next';
import { language, switchLanguage } from '@/plugins/i18next.ts';
import { handleEventGtag } from '@/utils/gtm.ts';

export const useUserStore = defineStore('user', () => {
    const data = reactive({
        user: {
            loading: false,
            content: null,
        } as StoreData<UserQuery['Coaching']['me']>,
        showStory: {
            loading: false,
            content: false,
        } as StoreData<boolean>,
        randomUsernames: {
            loading: false,
            content: [],
        } as StoreData<string[]>,
    });
    const originalData = getDataDeepCopy(data);

    return {
        data,
        user: computed(() => {
            if (
                data.user.content === null &&
                !data.user.loading &&
                !window.location.pathname.includes('global') &&
                !window.location.pathname.includes('x/location') &&
                !window.location.pathname.includes('x/internal')
            ) {
                void fetchUser(data.user);
            }
            return data.user.content;
        }),
        keyOrigin: computed(() => {
            if (data.user.content !== null) {
                return `https://${window.location.origin.includes('www-preprod') ? 'www-preprod.' : ''}${data.user.content.site.domain}`;
            }
            return null;
        }),
        isPremium: computed(() => data.user.content?.premium ?? 'unset'),
        showStory: computed(() => data.showStory.content),
        randomUsernames: computed(() => {
            if (data.randomUsernames.content === null && !data.randomUsernames.loading) {
                void fetchRandomUsernames(data.randomUsernames);
            }
            return data.randomUsernames.content;
        }),
        $forceUpdateUser: () => fetchUser(data.user),
        $forceUpdateRandomUsernames: () => fetchRandomUsernames(data.randomUsernames),
        $reset: () => {
            resetStore(data, originalData);
        },
    };
});

// eslint-disable-next-line complexity
const fetchUser = async (user: StoreData<UserQuery['Coaching']['me']>, retry: number = 0, retryDelayMs: number = 100) => {
    const tmpToken = (await hasLocalStorageKey(keyToken)) ? localStorage.getItem(keyToken) : null;
    const isUnsubscribing = router.currentRoute.value.name === 'unsubscribe';

    user.loading = true;

    if (tmpToken) {
        token.value = tmpToken;

        const { data, error } = await (
            await getApolloClient()
        ).query<UserQuery>({
            query: UserDocument,
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
        });

        if (!error) {
            user.content = data.Coaching.me;
            if (user.content.user.email) {
                handleEventGtag({ is_delirer: user.content.user.email.includes('delirer') });
            }
            handleEventGtag({ webmaster_id: user.content.webmasterId.toString() });
        } else {
            user.content = null;
            await waitTime(1000);
        }

        if (user.content === null) {
            if (retry >= 0) {
                return Promise.resolve(
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(fetchUser(user, --retry, retryDelayMs));
                        }, retryDelayMs);
                    })
                );
            }
            await logout();
            return;
        }

        if (user.content.language && (user.content.language.code !== language.value || user.content.language.code !== i18next.language)) {
            await switchLanguage(user.content.language.code);
        }

        // * check if user is refunded
        if (user.content.coachingOrders?.find((order) => order.refunded)) {
            if (router.currentRoute.value.name !== 'login') {
                await logout(true, { accountDisabled: 'true' });
            } else {
                // /!\ Never use useTranslation outside of a vue component
                message.value = i18next.t('Ton compte a été désactivé', { defaultValue: 'Ton compte a été désactivé' });
                localStorage.removeItem(keyToken);
            }
            // eslint-disable-next-line require-atomic-updates
            user.loading = false;
            return;
        }

        let origin = (await hasLocalStorageKey(keyOrigin)) ? localStorage.getItem(keyOrigin) : null;
        if (!origin) {
            origin = `https://${window.location.origin.includes('www-preprod') ? 'www-preprod.' : ''}${user.content.site.domain}`;
            localStorage.setItem(keyOrigin, origin);
        }

        if (!['dev', 'kube'].includes(keyEnv)) {
            if (user.content.site.redirectURL && user.content.site.redirectURL !== window.location.host && user.content.site.redirectURL !== '' && !isUnsubscribing) {
                localStorage.clear();
                const pathUrl = `${window.location.pathname.replace('/logged', '')}/`.replace('//', '/');
                let redirectUrl = addParamToUrl('token', tmpToken, `https://${user.content.site.redirectURL}/x/internal${pathUrl}`, false);

                const currentUrl = new URL(window.location.toString());
                const my_client_id = currentUrl.searchParams.get('myclid') ?? localStorage.getItem(keyClientID);
                if (my_client_id) {
                    redirectUrl = addParamToUrl('myclid', my_client_id, redirectUrl, false);
                    const my_session_id = currentUrl.searchParams.get('myssid');
                    if (my_session_id) {
                        redirectUrl = addParamToUrl('myssid', my_session_id, redirectUrl, false);
                    }
                }
                if (currentUrl.searchParams.get('emconf')) {
                    redirectUrl = addParamToUrl('emconf', 'true', redirectUrl, false);
                }

                window.location.replace(
                    addParamToUrl(
                        'origin',
                        origin,
                        buildUrlWithConsent({
                            currentUrlToRedirect: redirectUrl,
                        }),
                        false
                    )
                );
            }
        } else if (localStorage.getItem('singleUrlRegister') === 'true') {
            // if in dev or kube env (envs that never redirect to different domain)
            // and if comes from register page
            localStorage.removeItem('singleUrlRegister');

            const pathUrl = `${window.location.pathname.replace('/logged', '')}/`.replace('//', '/');
            const redirectUrl = addParamToUrl('token', tmpToken, `${window.location.origin}/x/internal${pathUrl}`, false);

            window.location.replace(
                addParamToUrl(
                    'origin',
                    origin,
                    buildUrlWithConsent({
                        currentUrlToRedirect: redirectUrl,
                    }),
                    false
                )
            );
        }

        globalHost.value = `https://${user.content.site.domain}`;
        datingToken.value = user.content.datingToken;
        stateCurrentOrder.value = null;

        if (!user.content.beRebilled) {
            const { data: orderData } = await (
                await getApolloClient()
            ).query<GetCurrentOrderQuery>({
                query: GetCurrentOrderDocument,
                errorPolicy: 'all',
                fetchPolicy: 'no-cache',
            });
            if (orderData.Coaching.me.currentCoachingOrder) {
                stateCurrentOrder.value = orderData.Coaching.me.currentCoachingOrder;
            }
        }

        // * painful email layer
        const displayNb = sessionStorage.getItem(keyLayer);
        if (user.content.user.emailValidatedAt === null) {
            if (!displayNb || Number(displayNb) < 3) {
                confirmEmailRequired.value = true;
            }
        }

        // * checks that the user has or has had a subscription
        if (router.currentRoute.value.meta.freeContent) {
            user.loading = false;
            return;
        }

        if (router.currentRoute.value.name === 'login' || router.currentRoute.value.name === 'p-g-a') {
            await router.push('/logged');
        }
    }

    // eslint-disable-next-line require-atomic-updates
    user.loading = false;
    // eslint-disable-next-line no-useless-return
    return;
};

const fetchRandomUsernames = async (randomUsernames: StoreData<RandomUsernamesQuery['Coaching']['randomUsernames']>) => {
    randomUsernames.loading = true;

    const { data, error } = await (
        await getApolloClient()
    ).query<RandomUsernamesQuery>({
        query: RandomUsernamesDocument,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
    });

    if (!error) {
        if (data.Coaching.randomUsernames.length > 0) {
            randomUsernames.content = data.Coaching.randomUsernames;
        }
    }

    randomUsernames.loading = false;
    return randomUsernames.content;
};
