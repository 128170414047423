import { ref } from 'vue';
import i18next from 'i18next';
import _ from 'lodash';
import { allPiniaStores } from '@/utils/pinia.ts';
import { setupApollo } from '@/apollo.ts';

declare module 'i18next' {
    interface CustomTypeOptions {
        defaultNS: string;
        fallbackNS: string;
    }
}

const specialLanguages = ['en-GB', 'es-ES', 'pt-PT'];

export const language = ref(i18next.language);
export const availableLanguages = ['de', 'en', 'fr', 'it', ...specialLanguages];

export function i18nFormat(lang: string) {
    const matchSpecialLanguage = specialLanguages.find((lng) => lng === lang || lng.split('-')[0] === lang || lng.split('-')[1] === lang);
    if (matchSpecialLanguage) {
        return matchSpecialLanguage;
    }

    if (lang.indexOf('-')) {
        return _.head(lang.split('-')) ?? 'fr';
    }

    return 'fr';
}

// Dynamic import of languages
export function switchLanguage(lang: (typeof availableLanguages)[number]) {
    return new Promise((resolve, reject) => {
        if (availableLanguages.includes(lang)) {
            import(`@/i18n/${lang}/front.json`)
                .then((importedLng: { default: unknown }) => {
                    i18next
                        .addResourceBundle(lang, 'front', importedLng.default)
                        .changeLanguage(lang)
                        .then(() => {
                            language.value = lang;

                            // we setup the apollo client with the new language
                            void setupApollo(true).then(() => {
                                // we reset all stores to reload the data with the new language
                                for (const store of allPiniaStores.value) {
                                    if (store.$id !== 'user') {
                                        store.$reset();
                                    }
                                }

                                resolve(true);
                            });
                        })
                        .catch(() => {
                            reject(`i18n error, can't change language to ${lang}`);
                        });
                })
                .catch(() => {
                    reject(`Error while importing "@/i18n/${lang}/front.json"`);
                });
        } else {
            reject(`Language "${lang}" not in availableLanguages`);
        }
    });
}
