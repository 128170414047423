import { keyToken, isLoading, token, keyLayer, keyOrigin, hasLocalStorageKey, keyEnv } from '@/states/_globals';
import { useUserStore } from '@/stores/user.ts';
import { buildUrlWithConsent } from 'vue-tools/src/didomi/didomi.ts';
import { useSiteStore } from '@/stores/site.ts';

export async function logout(redirect: boolean = true, extraParams: Record<string, string> = {}) {
    isLoading.value = true;

    try {
        localStorage.removeItem(keyToken);
        sessionStorage.removeItem(keyLayer);
        token.value = null;
    } catch {
        localStorage.clear();
        sessionStorage.clear();
        token.value = null;
    }

    isLoading.value = false;

    if (redirect) {
        if (keyEnv !== 'dev') {
            const url = new URL(buildUrlWithConsent({ currentUrlToRedirect: ((await hasLocalStorageKey(keyOrigin)) ? localStorage.getItem(keyOrigin) : null) ?? '/' }));
            for (const [key, value] of Object.entries(extraParams)) {
                url.searchParams.set(key, value);
            }
            window.location.replace(url.toString());
        } else {
            window.location.replace('/');
        }
    } else {
        useSiteStore().$reset();
        useUserStore().$reset();
    }
}
