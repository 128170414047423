<script setup lang="ts">
import { isLoading, isWaiting, keyOrigin } from '@/states/_globals.ts';
import { areStoresLoading } from '@/utils/pinia.ts';
import { onBeforeMount } from 'vue';
import emojisByDomains from './emojis.json';

withDefaults(
    defineProps<{
        forceLoading?: boolean;
    }>(),
    {
        forceLoading: false,
    }
);

type EmojisByDomain = {
    default: string[];
    byDomains: [
        {
            domains: string[];
            emojis: string[];
        },
    ];
};

const getDomainWithoutSubdomain = (url: string) => {
    const urlParts = new URL(url).hostname.split('.');

    return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.');
};

const emojis =
    (emojisByDomains as EmojisByDomain).byDomains
        .find((e) => e.domains.includes(getDomainWithoutSubdomain(localStorage.getItem(keyOrigin) ?? import.meta.env.VITE_FAKE_HOST ?? window.location.origin)))
        ?.emojis?.shuffle() ?? (emojisByDomains as EmojisByDomain).default.shuffle();

onBeforeMount(() => {
    const styleId = 'emoji-keyframes';
    if (!document.getElementById(styleId)) {
        // On génère la règle @keyframes avec des pourcentages calculés
        const keyframes = `
@keyframes emojiSlideCycle {
  0% {
    left: 100%;
    opacity: 0;
  }
  ${(100 / (emojis.length * 0.6) / 2).toFixed(2)}% {
    left: 50%;
    opacity: 1;
  }
  ${(100 / (emojis.length * 0.6)).toFixed(2)}% {
    left: 0%;
    opacity: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
    `;
        // Création et injection de la balise style dans le head
        const styleEl = document.createElement('style');
        styleEl.id = styleId;
        styleEl.textContent = keyframes;
        document.head.appendChild(styleEl);
    }
});
</script>

<template>
    <keep-alive>
        <div
            :class="{ 'pointer-events-none !opacity-0': !forceLoading && !isLoading && !isWaiting && !areStoresLoading }"
            class="fixed inset-0 z-[999999] flex items-center justify-center bg-white opacity-100 transition-opacity"
        >
            <div class="relative h-full w-[300px] overflow-visible">
                <span
                    v-for="(emoji, index) in emojis"
                    :key="emoji"
                    :style="{
                        animationDuration: `${emojis.length * 0.5}s`,
                        animationDelay: `-${index * 0.5}s`,
                    }"
                    class="absolute top-1/2 -translate-y-1/2 animate-[emojiSlideCycle_0s_linear_infinite] text-[64px]"
                    >{{ emoji }}</span
                >
            </div>
        </div>
    </keep-alive>
</template>

<style></style>
