/* eslint-disable @typescript-eslint/no-unsafe-return */
declare global {
    interface Array<T> {
        first<B extends boolean>(assert?: B): B extends true ? T : T | undefined;
        last<B extends boolean>(assert?: B): B extends true ? T : T | undefined;
        filterNullAndUndefined(): Exclude<T, null | undefined>[];
        filterError(): Exclude<T, Error>[];
        filter<U extends T>(pred: (value: T, index: number, array: T[]) => value is U): U[];
        filterAsync<U extends T>(pred: (value: T, index?: number, array?: T[]) => Promise<boolean>): Promise<U[]>;
        groupBy(predicate: (v: T) => string | number): Record<string | number, T[]>;
        groupByKey<K extends keyof T>(key: keyof T): Record<K, T[]>;

        /**
         * Split the array into chunks of the given size
         *
         * Last chunk will contain the remaining elements and may be smaller than the chunk size
         * @param chunkSize
         */
        chunkBySize(chunkSize: number): T[][];

        /**
         * Split the array into the given number of chunks
         *
         * @param chunkCount
         */
        chunkByCount(chunkCount: number): T[][];

        /**
         * Shuffle in place
         */
        shuffle(): T[];

        sum(this: number[]): number;
        avg(this: number[]): number;
        unique<T extends number | string>(this: T[]): T[];

        mapKey<Key extends keyof T>(key: Key): T[Key][];

        sample(): T;

        // Swap 2 items based on their indexes
        swap(a: number, b: number): this;

        // ⚠️ Must copy all functions to ReadonlyArray below
    }

    interface ReadonlyArray<T> {
        first<B extends boolean>(assert?: B): T extends never ? undefined : B extends true ? T : T | undefined;
        last<B extends boolean>(assert?: B): B extends true ? T : T | undefined;
        filterNullAndUndefined(): Exclude<T, null | undefined>[];
        filterError(): Exclude<T, Error>[];
        filter<U extends T>(pred: (value: T, index: number, array: T[]) => value is U): U[];
        filterAsync<U extends T>(pred: (value: T, index?: number, array?: T[]) => Promise<boolean>): Promise<U[]>;
        groupBy(predicate: (v: T) => string | number): Record<string | number, T[]>;
        groupByKey<K extends keyof T>(key: keyof T): Record<K, T[]>;
        shuffle(): void;
        sum(this: number[]): number;
        avg(this: number[]): number;
        unique<T extends number | string>(this: T[]): T[];
        sample(): T;
        mapKey<Key extends keyof T>(key: Key): T[Key][];

        chunkBySize(chunkSize: number): T[][];
        chunkByCount(chunkCount: number): T[][];
    }

    interface ArrayConstructor {
        range(start: number, end: number): number[];
    }
}

Array.prototype.first = function (assert?: boolean) {
    if (assert && this.length === 0) {
        throw new Error('Arrray is empty, cannot get first element with assert=true');
    }
    return this.length > 0 ? this[0] : undefined;
};

Array.prototype.last = function (assert?: boolean) {
    const n = this.length;
    if (assert && n === 0) {
        throw new Error('Array is empty, cannot get last element with assert=true');
    }
    return n > 0 ? this[n - 1] : undefined;
};

Array.prototype.filterNullAndUndefined = function () {
    return this.filter((item) => item !== null && item !== undefined);
};
Array.prototype.filterError = function () {
    return this.filter((item) => !(item instanceof Error));
};

Array.prototype.filterAsync = async function (predicate) {
    const results = await Promise.all(this.map(predicate));
    return this.filter((_, index) => results[index]);
};

Array.prototype.groupBy = function (predicate) {
    return this.reduce((acc, value) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ((acc[predicate(value)] ||= []) as unknown[]).push(value);
        return acc;
    }, {});
};
Array.prototype.groupByKey = function (key) {
    // node next Object.groupBy(items, (o) => o[key])
    return this.reduce((acc, value) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ((acc[value[key]] ||= []) as unknown[]).push(value);
        return acc;
    }, {});
};

Array.prototype.shuffle = function () {
    for (let i = this.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        [this[i], this[j]] = [this[j], this[i]];
    }
    return this;
};

Array.prototype.sum = function () {
    return this.reduce((a, b) => a + b, 0);
};

Array.prototype.avg = function () {
    return this.sum() / this.length || 0;
};

Array.prototype.unique = function () {
    return [...new Set(this)];
};

Array.prototype.sample = function () {
    return this[Math.floor(Math.random() * this.length)];
};

Array.range = (start, end) => Array.from({ length: end - start }, (_, k) => k + start);

Array.prototype.mapKey = function (key) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.map((item) => item[key]);
};

Array.prototype.swap = function (a, b) {
    // eslint-disable-next-line prefer-destructuring,@typescript-eslint/no-unsafe-assignment
    this[a] = this.splice(b, 1, this[a])[0];
    return this;
};

Array.prototype.chunkBySize = function (chunkSize) {
    return Array.from({ length: Math.ceil(this.length / chunkSize) }, (_, i) => this.slice(i * chunkSize, i * chunkSize + chunkSize));
};

Array.prototype.chunkByCount = function (chunkCount) {
    const chunkSize = Math.ceil(this.length / chunkCount);
    return Array.from({ length: chunkCount }, (_, i) => this.slice(i * chunkSize, i * chunkSize + chunkSize));
};

export {};
