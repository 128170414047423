import { App } from 'vue';
import * as Sentry from '@sentry/vue';
import { router } from '@/router.ts';
import { withoutGraphQLFetch } from 'apollo-link-sentry';

export default {
    install: (app: App) => {
        if (import.meta.env.VITE_ENV !== 'dev') {
            Sentry.init({
                app,
                dsn: 'https://8ad255d20a5b7dc1286217c8e5f64297@o1310146.ingest.sentry.io/4506376552120320',
                integrations: [Sentry.browserTracingIntegration({ router })],
                tracePropagationTargets: [/(supergraph|graphql)/, /\/n\//],
                release: '1.0.0',
                environment: import.meta.env.VITE_ENV === 'preprod' ? 'preprod' : 'production',
                logErrors: true,
                // Performance Monitoring
                tracesSampleRate: import.meta.env.VITE_ENV === 'preprod' ? 1.0 : 0.001,
                ignoreErrors: [
                    /Network ?Error/,
                    /Unable to preload CSS/,
                    /dynamically imported module/,
                    /Authentication failed, wrong credentials/,
                    /Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type/,
                    /Importing a module script failed/,
                    /Load failed/i,
                    /ApolloError/i,
                ],
                beforeBreadcrumb: withoutGraphQLFetch((breadcrumb) => {
                    if (breadcrumb.category === 'ui.click') {
                        return null;
                    }
                    return breadcrumb;
                }),
            });
        }
    },
};
