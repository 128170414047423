import { computed, reactive, watch } from 'vue';
import { getDataDeepCopy, handleError, resetStore, StoreData, waitTime } from '@/utils/pinia.ts';
import { defineStore, storeToRefs } from 'pinia';
import i18next from 'i18next';
import { CoachingContentLayerPaymentModel, GetContentLayerPaymentDocument, GetContentLayerPaymentQuery } from '@/generated/gql.coaching.ts';
import { getApolloClient } from '@/apollo.ts';
import { useUserStore } from '@/stores/user.ts';
type Niche = {
    id: number;
    internalName: string;
    img: string;
    title: string;
    catchLine: string;
};

export const useNicheStore = defineStore('niche', () => {
    const userStore = storeToRefs(useUserStore());
    const data = reactive({
        niches: {
            loading: false,
            content: null,
        } as StoreData<Niche[]>,
        currentNiche: {
            loading: false,
            content: null,
        } as StoreData<Niche>,
        layerPayment: {
            loading: false,
            content: null,
        } as StoreData<GetContentLayerPaymentQuery['Coaching']['contentLayerPayment']>,
    });
    const originalData = getDataDeepCopy(data);

    const updateCurrentNiche = (niches: Niche[]) => {
        if (userStore.user.value && userStore.user.value.user.userHasNiches) {
            for (const uhn of userStore.user.value.user.userHasNiches) {
                const niche = niches.find((n) => n.internalName === uhn.niche.internalName);
                if (niche) {
                    data.currentNiche.content = niche;
                    break;
                }
            }
        }
    };

    const niches = computed(() => {
        if (data.niches.content === null && !data.niches.loading) {
            void fetchNiches(data.niches).then(() => {
                updateCurrentNiche(data.niches.content ?? []);
            });
        }
        return data.niches.content;
    });

    const currentNiche = computed(() => {
        if (!data.currentNiche.loading && data.currentNiche.content === null) {
            // If the 'niches' state has changed, we need to fetch the currentNiche again (this computed is re-calculated)

            // @ts-expect-warning
            // eslint-disable-next-line
            if (niches.value || true) {
                updateCurrentNiche(data.niches.content ?? []);
            }
        }

        return data.currentNiche.content;
    });

    const layerPayment = computed(() => {
        if (!data.layerPayment.loading && data.layerPayment.content === null) {
            // If the 'currentNiche' state has changed, we need to fetch the layerPayment again (this computed is re-calculated)
            if (currentNiche.value) {
                void fetchLayerPayment(data.layerPayment, currentNiche.value.id);
            }
        }

        return data.layerPayment.content;
    });

    watch(
        userStore.user,
        () => {
            updateCurrentNiche(data.niches.content ?? []);
        },
        { immediate: true }
    );

    return {
        data,
        niches,
        currentNiche,
        layerPayment,
        $reset: () => {
            resetStore(data, originalData);
        },
    };
});

const fetchNiches = async (niche: StoreData<Niche[]>) => {
    niche.loading = true;
    // /!\ Never use useTranslation outside of a vue component
    const { t } = i18next;
    niche.content = [
        {
            id: 1,
            internalName: 'Success',
            img: '/connection/pack360.jpeg',
            title: t('LE COACHING LE PLUS COMPLET', { defaultValue: 'LE COACHING LE PLUS COMPLET' }),
            catchLine: t('Deviens une personne attractive !', { defaultValue: 'Deviens une personne attractive !' }),
        },
        {
            id: 2,
            internalName: 'Irresistible',
            img: '/connection/irresistible.jpeg',
            title: t('SEDUCTION & DÉSIR', { defaultValue: 'SEDUCTION & DÉSIR' }),
            catchLine: t('Découvre la solution pour devenir un séducteur !', { defaultValue: 'Découvre la solution pour devenir un séducteur !' }),
        },
        {
            id: 7,
            internalName: 'Power',
            img: '/connection/power.jpeg',
            title: t('NUTRITION & SOCIAL', { defaultValue: 'NUTRITION & SOCIAL' }),
            catchLine: t('Découvre la solution pour devenir une force de la nature !', { defaultValue: 'Découvre la solution pour devenir une force de la nature !' }),
        },
        {
            id: 4,
            internalName: 'Mindset',
            img: '/connection/mindset.jpeg',
            title: t('LES MEILLEURS LIVRES', { defaultValue: 'LES MEILLEURS LIVRES' }),
            catchLine: t('La quintessence du développement personnel !', { defaultValue: 'La quintessence du développement personnel !' }),
        },
        {
            id: 5,
            internalName: 'Spirit',
            img: '/connection/spirit.jpeg',
            title: t('CONVICTION & CONNEXION', { defaultValue: 'CONVICTION & CONNEXION' }),
            catchLine: t('Éveille ta conscience émotionnelle et spirituelle !', { defaultValue: 'Éveille ta conscience émotionnelle et spirituelle !' }),
        },
        {
            id: 6,
            internalName: 'Money',
            img: '/connection/money.jpeg',
            title: t('FINANCE & BUSINESS', { defaultValue: 'FINANCE & BUSINESS' }),
            catchLine: t('La solution pour gagner & économiser de l’argent !', { defaultValue: 'La solution pour gagner & économiser de l’argent !' }),
        },
        {
            id: 3,
            internalName: 'Performance',
            img: '/connection/perfo.jpeg',
            title: t('SELF & ORGANISATION', { defaultValue: 'SELF & ORGANISATION' }),
            catchLine: t('Atteins tes objectifs rapidement avec nos méthodes ultimes !', { defaultValue: 'Atteins tes objectifs rapidement avec nos méthodes ultimes !' }),
        },
    ];
    await waitTime(50); // fake api call, avoid flickering

    niche.loading = false;
    return true;
};

let retriesLayerPaymentCount = 0;
const fetchLayerPayment = async (layerPayment: StoreData<GetContentLayerPaymentQuery['Coaching']['contentLayerPayment']>, currentNicheId: number) => {
    layerPayment.loading = true;
    retriesLayerPaymentCount++;

    try {
        const { data, error, errors } = await (
            await getApolloClient()
        ).query<GetContentLayerPaymentQuery>({
            query: GetContentLayerPaymentDocument,
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            variables: {
                model: CoachingContentLayerPaymentModel.Niche,
                modelId: currentNicheId,
            },
        });

        if (Array.isArray(data.Coaching.contentLayerPayment)) {
            layerPayment.content = data.Coaching.contentLayerPayment;
            // eslint-disable-next-line require-atomic-updates
            retriesLayerPaymentCount = 0;
        } else if ((errors && errors.length >= 1) || error) {
            throw new Error('Error while fetching layer payment');
        } else {
            layerPayment.content = null;
            await waitTime(1000);
        }
    } catch {
        layerPayment.content = null;
        if (retriesLayerPaymentCount === 3) {
            await handleError();
        } else {
            await waitTime(1000);
        }
    }

    layerPayment.loading = false;
};
