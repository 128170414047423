import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { datingToken, token } from './states/_globals';
import { onError } from '@apollo/client/link/error';
import { logout } from '@/services/user';
import { SentryLink } from 'apollo-link-sentry';
import { language } from '@/plugins/i18next.ts';
import { ref } from 'vue';
import { provideApolloClient } from '@vue/apollo-composable';

let apolloClient: ApolloClient<unknown> | undefined;
let apolloInitPromise: Promise<ApolloClient<unknown>> | null = null;
const trace_id = `${Math.random().toString(36).substring(2, 9)}`;

const uri = (import.meta.env.VITE_APP_GRAPHQL_URL as string | null | undefined) ?? `${location.protocol}//${location.host}/supergraph`;

const errorsInvalidToken = ['jwt malformed', 'invalid token', 'invalid signature', 'Unexpected token'];
const errorsExpiredToken = ['jwt expired', 'Token expired'];

export const apolloLoaded = ref(false);

// convert navigator language to xx_XX format
const getAcceptLanguage = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    let lng = (language.value ?? navigator.language).toLowerCase();
    if (!lng.includes('_') && !lng.includes('-')) {
        lng = lng.slice(0, 2);
        switch (lng) {
            // handle all special cases
            case 'ca':
                lng = 'ca_ES';
                break;
            case 'da':
                lng = 'da_DK';
                break;
            case 'el':
                lng = 'el_GR';
                break;
            case 'en':
                lng = 'en_US';
                break;
            case 'et':
                lng = 'et_EE';
                break;
            case 'nb':
                lng = 'nb_NO';
                break;
            case 'sq':
                lng = 'sq_AL';
                break;
            case 'sr':
                lng = 'sr_RS';
                break;
            case 'uk':
                lng = 'uk_UA';
                break;
            default:
                // default case is xx_XX
                lng = `${lng}_${lng.toUpperCase()}`;
                break;
        }
        return lng;
    }
    // in all other cases, we keep the first two letters and the last two letters
    const tmp = lng.replace('-', '_').split('_');
    return `${tmp[0].slice(0, 2)}_${tmp[1].slice(0, 2).toUpperCase()}`;
};

export function setupApollo(force = false) {
    if (apolloInitPromise && !force) {
        return apolloInitPromise;
    }

    apolloLoaded.value = false;
    apolloInitPromise = new Promise((resolve) => {
        apolloClient?.stop();

        const link = from([
            onError(({ graphQLErrors }) => {
                if (graphQLErrors?.some((error) => error.extensions?.code === 'EXPIRED_TOKEN')) {
                    void logout();
                }
            }),

            // Retry 3 times after failed request
            new RetryLink({
                attempts: (count, operation, error?: { result?: { errors?: { message: string }[] } }) => {
                    // const operationDoesNotTriggerLayerError = [] // expect error to be thrown by layer

                    const isErrorToken =
                        errorsInvalidToken.some((e) => error?.result?.errors?.[0]?.message.includes(e)) || errorsExpiredToken.some((e) => error?.result?.errors?.[0]?.message.includes(e));
                    return count < 3 && !isErrorToken;
                },
                delay: () => 300,
            }),
            new SentryLink({
                setTransaction: false,
                setFingerprint: false,
                attachBreadcrumbs: {
                    includeVariables: false,
                },
            }),

            // query or mutation name in dev tools
            createHttpLink({
                fetch: (u, options) => fetch(`${uri}?${(JSON.parse(options?.body as string) as { operationName: string }).operationName}`, options),
            }),
        ]);

        const client = new ApolloClient({
            link: setContext((req, { headers }) => {
                const isDatingOperation = req.operationName?.startsWith('dating');
                return {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    headers: {
                        ...headers,
                        'apollographql-client-name': 'front',
                        'apollographql-client-version': '1.0.0',
                        'x-trace-id': trace_id,
                        ...(token.value ? { bearer: token.value } : {}),
                        ...(isDatingOperation ? (datingToken.value ? { bearer: datingToken.value } : {}) : token.value ? { bearer: token.value } : {}),
                        'accept-language': getAcceptLanguage(),
                    },
                };
            }).concat(link),
            cache: new InMemoryCache(),
            name: 'front',
            version: '1.0.0',
        });

        apolloClient = client;
        provideApolloClient(client);
        resolve(client);
        apolloLoaded.value = true;
    });

    return apolloInitPromise;
}

// Fonction utilitaire pour obtenir le client initialisé
export function getApolloClient(): Promise<ApolloClient<unknown>> {
    if (!apolloInitPromise) {
        throw new Error('Apollo client not initialized. Call setupApollo() first.');
    }
    return apolloInitPromise;
}
