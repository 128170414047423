import { App } from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import {
    faEye as falEye,
    faEyeSlash as falEyeSlash,
    faPen,
    faHouse,
    faChevronRight,
    faChevronLeft,
    faLock,
    faCheck,
    faXmark,
    faLockKeyhole,
    faRightFromBracket,
    faChevronDown,
    faChevronUp,
    faPenToSquare,
    faTriangleExclamation,
    faCircleQuestion,
    faCircleCheck as falCircleCheck,
} from '@fortawesome/pro-light-svg-icons';
import {
    faClockRotateLeft,
    faHouseBlank,
    faCircleHalfStroke,
    faUser,
    faRectangleVerticalHistory,
    faMessage,
    faUnlockKeyhole,
    faCircle,
    faCircleInfo as farCircleInfo,
    faXmark as farXmark,
    faUserCircle,
    faGlassesRound,
    faVideo,
    faKey,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faStar,
    faEnvelopeDot,
    faPhoneFlip,
    faEnvelopeOpenText,
    faCircleCheck,
    faBars,
    faThumbsUp,
    faCircleInfo,
    faThumbsDown,
    faArrowUpRightFromSquare,
    faAtomSimple,
    faArrowUp,
    faLockKeyhole as fasLockKeyhole,
    faCheck as fasCheck,
    faEnvelope,
    faPhone,
    faCreditCard,
    faWreathLaurel,
    faBrain,
    faCalendarDays as fasFaCalendarDays,
    faPencil,
    faPlay,
    faPause,
    faVolumeOff,
    faCaretDown,
    faPenToSquare as faPenToSquareSolid,
    faSpinner,
    faArrowLeftFromBracket,
    faForward,
    faBackward,
    faUser as fasUser,
    faHeart,
    faEye as fasEye,
    faEyeSlash as fasEyeSlash,
    faUserPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeft, faCircleXmark, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faApplePay } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
    faStar,
    faSpinnerThird,
    faVideo,
    faGlassesRound,
    faEnvelopeDot,
    faPhoneFlip,
    faEnvelopeOpenText,
    falEye,
    falEyeSlash,
    faPen,
    faApplePay,
    faClockRotateLeft,
    faTriangleExclamation,
    faCircle,
    faCircleXmark,
    faHouse,
    faBars,
    faCircleInfo,
    faChevronRight,
    faChevronLeft,
    faLock,
    faCircleHalfStroke,
    faCheck,
    faPlay,
    faXmark,
    faCircleCheck,
    faLockKeyhole,
    faUnlockKeyhole,
    faHouseBlank,
    faRightFromBracket,
    faUser,
    faRectangleVerticalHistory,
    faChevronDown,
    faCircleInfo,
    farCircleInfo,
    faMessage,
    faPlay,
    faThumbsUp,
    faThumbsDown,
    faArrowUpRightFromSquare,
    faChevronUp,
    faPenToSquare,
    faAtomSimple,
    faArrowLeft,
    faArrowUp,
    fasLockKeyhole,
    farXmark,
    fasCheck,
    faEnvelope,
    faPhone,
    faUserCircle,
    faCreditCard,
    faWreathLaurel,
    faBrain,
    fasFaCalendarDays,
    faPencil,
    faPause,
    faVolumeOff,
    faCaretDown,
    faPenToSquareSolid,
    faSpinner,
    faCheck,
    faArrowLeftFromBracket,
    faCircleQuestion,
    faForward,
    faBackward,
    faCircleInfo,
    fasUser,
    falCircleCheck,
    faKey,
    faHeart,
    fasEye,
    fasEyeSlash,
    faUserPlus
);

export default {
    install: (app: App) => {
        app.component('FontAwesomeIcon', FontAwesomeIcon).component('font-awesome-layers', FontAwesomeLayers).component('font-awesome-layers-text', FontAwesomeLayersText);
    },
};
