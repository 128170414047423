import { Component, createApp } from 'vue';
import { createHead } from '@unhead/vue';
import './style.css';
import App from '@/App.vue';
import { router } from '@/router.ts';
import { pinia } from '@/utils/pinia.ts';
import fontAwesome from '@/plugins/font-awesome.ts';
import { availableLanguages, language, switchLanguage, i18nFormat } from '@/plugins/i18next.ts';
import i18next from 'i18next';
import sentry from '@/plugins/sentry.ts';
import didomi from '@/plugins/didomi.ts';
import I18NextVue from 'i18next-vue';

const app = createApp(App as Component)
    .use(didomi)
    .use(sentry)
    .use(fontAwesome)
    .use(router)
    .use(createHead());

i18next.on('languageChanged', (lng) => {
    language.value = lng;
});

void i18next
    .init({
        lng: i18nFormat(navigator.language),
        fallbackLng: 'en',
        supportedLngs: availableLanguages,
        ns: 'front',
        defaultNS: 'front',
        interpolation: { prefix: '{', suffix: '}' },
    })
    .then(() => {
        switchLanguage(i18nFormat(navigator.language))
            .catch(() => switchLanguage('fr'))
            .finally(() => {
                // Init pinia stores after i18next is ready cause graphql queries are language dependent
                app.use(I18NextVue, { i18next }).use(pinia).mount('#app');
            });
    });
