import { createRouter, createWebHistory } from 'vue-router';
import { hasLocalStorageKey, keyToken } from '@/states/_globals';
import { addBreadcrumb } from '@sentry/vue';
import * as Sentry from '@sentry/vue';

export const router = createRouter({
    // scrollBehavior(to) {
    //     if (to.hash) {
    //         return {
    //             selector: to.hash,
    //             behavior: 'smooth',
    //         };
    //     }
    //     return {
    //         top: 0,
    //         behavior: 'smooth',
    //     };
    // },
    history: createWebHistory(),
    routes: [
        { name: 'global-lp', path: '/global', component: () => import('@/views/Global-LP.vue') },
        { name: 'register', path: '/register', component: () => import('@/views/Register.vue') },
        { name: 'confirmation-payment', path: '/payment/confirmation-payment', component: () => import('@/views/logged/Confirmation-Payment.vue') },
        { name: 'retract-form', path: '/dfiles/:lng/retract-form.pdf', component: () => import('@/views/RetractForm.vue'), meta: { title: 'RetractForm' } },
        { name: '404', path: '/:path(.*)', component: () => import('@/views/404.vue'), meta: { title: '404' } },
        {
            path: '/',
            component: () => import('@/layouts/Unlogged.vue'),
            children: [
                { name: 'login', path: '', component: () => import('@/views/Home.vue') },
                { name: 'p-g-a', path: '/support/:backUrl?', component: () => import('@/views/PGA.vue') },
                { name: 'colors', path: 'colors', component: () => import('@/views/Colors.vue') },
            ],
        },
        {
            // Content that only needs an account without subscription
            path: '/',
            component: () => import('@/layouts/LoggedFreeContent.vue'),
            meta: { freeContent: true },
            children: [{ name: 'book-audio', path: 'logged/book-audio/:slug', component: () => import('@/views/logged/BookAudio.vue') }],
        },
        {
            // Logged site with restricted content
            path: '/',
            component: () => import('@/layouts/Logged.vue'),
            meta: { logged: true, blockedContent: true, isFreeContentRoute: false },
            children: [
                {
                    name: 'payment',
                    path: '/payment',
                    component: () => import('@/views/logged/Payment.vue'),
                    meta: { title: 'Payment', layoutProps: { navbarStyle: 'grey', navbarLogoAlign: 'left' }, showRandomNotification: true },
                },
                // {
                //     name: 'book-audio',
                //     path: 'logged/book-audio/:slug',
                //     component: () => import('@/views/logged/BookAudio.vue'),
                //     meta: { title: 'book-audio', layoutProps: { navbarStyle: 'dark', navbarLogoAlign: 'left' } },
                // },
            ],
        },
        {
            name: 'x',
            path: '/x',
            component: () => import('@/layouts/X.vue'),
            children: [
                { name: 'confirm-email', path: 'confirmation', component: () => import('@/views/x/ConfirmEmail.vue'), meta: { title: 'Confirmation' } },
                { name: 'location', path: 'location/:location(.*)', component: () => import('@/views/x/Location.vue'), meta: { title: 'Location' } },
                { name: 'registration', path: 'registration', component: () => import('@/views/x/Registration.vue'), meta: { title: 'Registration' } },
                { name: 'unsubscribe', path: 'unsubscribe', component: () => import('@/views/x/Unsubscribe.vue'), meta: { title: 'Unsubscribe' } },
                { name: 'internal', path: 'internal/:internal(.*)', component: () => import('@/views/x/Internal.vue'), meta: { title: 'Internal' } },
            ],
        },
        {
            name: 'logged',
            path: '/logged',
            component: () => import('@/layouts/Logged.vue'),
            meta: { logged: true, layoutProps: { navbarStyle: 'dark', navbarSize: 'bigger' } },
            children: [
                { name: 'home', path: '', component: () => import('@/views/logged/Home.vue') },
                { name: 'themes', path: 'themes/:slug', component: () => import('@/views/logged/Theme.vue'), meta: { title: 'Theme' } },
                { name: 'articles', path: 'articles/:theme/:slug', component: () => import('@/views/logged/Article.vue'), meta: { title: 'Article', backToTop: true } },
                {
                    name: 'account',
                    path: 'account',
                    children: [
                        { name: 'me', path: '', component: () => import('@/views/logged/account/Home.vue'), meta: { title: 'Account' } },
                        { name: 'subscription', path: 'subscription', component: () => import('@/views/logged/account/Subscription.vue'), meta: { title: 'Subscription' } },
                    ],
                },
            ],
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    document.title = typeof to.meta.title === 'string' ? to.meta.title : '';

    if (!(await hasLocalStorageKey(keyToken)) && to.meta.logged) {
        next('/');
    } else {
        next();
    }
    addBreadcrumb({
        category: 'router before each',
        message: JSON.stringify({
            fromFullPath: from.fullPath,
            toFullPath: to.fullPath,
        }),
        level: 'info',
    });
});

router.onError((error, to) => {
    Sentry.captureException(error);

    if (error instanceof Error && error.message.includes('Failed to fetch dynamically imported module')) {
        // eslint-disable-next-line no-console
        console.warn(error);
        window.location.hash = to.fullPath;
        window.location.reload();
    }
});
