<script setup lang="ts">
/* eslint-disable no-undef */
import { aBottomLayerIsOpen, aModalIsOpen, isLoading, sideBar } from '@/states/_globals';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useSiteStore } from '@/stores/site.ts';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user.ts';
import { language } from '@/plugins/i18next.ts';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const siteStore = useSiteStore();
const userStore = useUserStore();
const route = useRoute();
const zeLoaded = ref(false);
const zeIconLoading = ref(false);
const isClickable = computed(() => !zeHidedPage.value && language.value === 'fr' && zeLoaded && !aBottomLayerIsOpen.value && !aModalIsOpen.value);
const zeIsOpen = ref(false);

onMounted(() => {
    watch(storeToRefs(siteStore).site, (site) => {
        if (site?.options?.zendesk?.key) {
            const src = `https://static.zdassets.com/ekr/snippet.js?key=${site.options.zendesk.key}`;

            if (!document.querySelector(`script[src="${src}"]`)) {
                const script = document.createElement('script');
                script.id = 'ze-snippet';
                script.defer = true;
                script.src = src;
                // https://developer.zendesk.com/api-reference/widget/settings/
                // @ts-expect-error zendesk is ext script
                window.zESettings = {
                    webWidget: {
                        offset: { horizontal: '48px', vertical: '48px' },
                        chat: {
                            connectOnPageLoad: false,
                        },
                        zIndex: 99, // decrease for layers
                        color: {
                            theme: siteStore.site?.options?.style?.theme.colors.primary[-100],
                        },
                    },
                };

                document.head.append(script);
                script.addEventListener('load', () => {
                    // @ts-expect-error zendesk is ext script
                    zE('messenger', 'hide');

                    // @ts-expect-error zendesk is ext script
                    zE('messenger:on', 'close', () => {
                        zeIconLoading.value = false;
                        // @ts-expect-error zendesk is ext script
                        zE('messenger', 'hide');
                        setTimeout(() => {
                            zeIsOpen.value = false;
                        }, 800);
                    });

                    // @ts-expect-error zendesk is ext script
                    zE('messenger:on', 'open', () => {
                        zeIconLoading.value = true;
                        zeIsOpen.value = true;
                        setTimeout(() => {
                            // @ts-expect-error zendesk is ext script
                            zE('messenger', 'show');
                        }, 100);
                    });

                    setTimeout(() => {
                        zeLoaded.value = true;
                    }, 500);
                });
            }
        }
    });
});

const zeHidedPage = computed(
    () =>
        isLoading.value ||
        sideBar.value ||
        storeToRefs(userStore).user?.value ||
        route.path === '/register' ||
        route.path.includes('/dfiles') ||
        route.path.includes('/global') ||
        route.meta?.freeContent
);

const openZe = () => {
    // @ts-expect-error zendesk is ext script
    if (typeof zE === 'function') {
        // @ts-expect-error zendesk is ext script
        zE(() => {
            // @ts-expect-error zendesk is ext script
            zE('messenger', 'open');
        });
    }
};
</script>

<template>
    <div class="fixed bottom-0 right-0 z-[999999] h-[100px] w-[80px] overflow-hidden text-white transition-all">
        <div
            :class="{ 'ml-[80px]': !isClickable, '!ml-0 !mt-[20px] !size-[64px]': zeIsOpen }"
            class="ml-[12px] mt-[16px] flex size-[48px] cursor-pointer items-center justify-center rounded-full bg-primary-100 transition-all duration-300"
            @click="isClickable && openZe()"
        >
            <FontAwesomeIcon class="text-[24px]" :class="{ 'animate-spin': zeIconLoading }" :icon="zeIconLoading ? ['fad', 'spinner-third'] : ['fal', 'circle-question']" fixedWidth />
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
