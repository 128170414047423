declare const window: Window & { dataLayer: Record<string, unknown>[] };

export function handleScriptGtm() {
    const scriptGtmHead = document.createElement('script');
    scriptGtmHead.text =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W7VXMJF7');";
    document.head.appendChild(scriptGtmHead);

    const scriptGtmBody = document.createElement('noscript');
    scriptGtmBody.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7VXMJF7" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.body.appendChild(scriptGtmBody);
}

export enum TransactionEventName {
    Confirm_Payment = 'Confirm_Payment',
    Inscription_User = 'Inscription_User',
    Mail_Validation = 'Mail_Validation',
}

type GoogleAnalyticsEvent = {
    my_client_id?: string;
    my_session_id?: string;
    niche_name?: string;
    site_domain?: string;
    is_delirer?: boolean;
    webmaster_id?: string;
};

type TransactionEvent = {
    event: TransactionEventName;
    ltr_value: number;
    transaction_id: string;
    currency: string;
    contents?: { id: string; quantity: number }[];
};

// ! not use vue-gtag
export const handleEventGtag = (event: TransactionEvent | GoogleAnalyticsEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ...event,
    });
};
