import { createPinia, Store } from 'pinia';
import { computed, Reactive, ref } from 'vue';
import { logout } from '@/services/user.ts';
import { buildUrlWithConsent } from 'vue-tools/src/didomi/didomi.ts';

export type StoreData<T> = { loading: boolean; content: T | null };
type CoachingStore<T> = Store &
    T & {
        data: Reactive<Record<string, StoreData<unknown>>>;
        originalData: Record<string, StoreData<unknown>>;
        $reset(): void;
    };

export const allPiniaStores = ref<CoachingStore<unknown>[]>([]);
export const pinia = createPinia();
export const areStoresLoading = computed(() => allPiniaStores.value.some((store) => Object.values(store.data).some((item: StoreData<unknown>) => item.loading)));

pinia.use((p) => {
    allPiniaStores.value.push(p.store as CoachingStore<unknown>);
});

export const getDataDeepCopy = (storeData: Reactive<Record<string, StoreData<unknown>>>) =>
    Object.fromEntries(
        Object.entries(storeData).map(([key, refValue]) => [
            key,
            {
                loading: refValue.loading,
                content: refValue.content,
            },
        ])
    );

export const resetStore = (storeData: Reactive<Record<string, StoreData<unknown>>>, originalStoreData: Record<string, StoreData<unknown>>) => {
    for (const key of Object.keys(storeData)) {
        storeData[key].loading = originalStoreData[key].loading;
        storeData[key].content = originalStoreData[key].content;
    }
};

export const waitTime = (timeInMs: number) =>
    new Promise((resolve) => {
        setTimeout(resolve, timeInMs);
    });

export const handleError = async () => {
    await logout(false);
    window.location.href = buildUrlWithConsent({ currentUrlToRedirect: '/global' });
};
