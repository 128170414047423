import { removeParamFromUrl } from '@/utils/utils.ts';

// Didomi main's script is located just after <head> in index.html
export default {
    install: () => {
        window.didomiEventListeners = window.didomiEventListeners || [];
        window.didomiEventListeners.push(
            {
                // Dynamic privacy policy link in the preferences modal
                event: 'preferences.shown',
                listener: () => {
                    setTimeout(() => {
                        const ppLink = document.querySelector('[href="https://www.100recipesforsuccess.com?modal=pp"]') as HTMLAnchorElement | null | undefined;
                        if (ppLink) {
                            ppLink.href = `/?modal=pp`;
                        }
                    }, 100);
                },
            },
            ...['sync.ready', 'consent.changed'].map((event) => ({
                // Remove the external consent value from the URL (without reloading the page)
                event,
                listener: () => {
                    setTimeout(() => {
                        removeParamFromUrl('didomiConfig.user.externalConsent.value');
                    }, 100);
                },
            }))
        );
    },
};
