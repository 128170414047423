import { ref, Ref } from 'vue';
import { GetOrdersByCreditCardQuery, GetCurrentOrderQuery } from '@/generated/gql.coaching';

export const token = ref<string | null>();
export const datingToken = ref<string | null>();

// * vite env vashowBottomLayerriables
export const keyToken: string = (import.meta.env.VITE_TOKEN_NAME as string | undefined | null) ?? 'octopus:front:token';
export const keyLayer: string = (import.meta.env.VITE_PAINFUL_LAYER as string | undefined | null) ?? 'octopus:front:layer';
export const keyEnv: string = (import.meta.env.VITE_ENV as string | undefined | null) ?? '';
export const keyOrigin: string = (import.meta.env.VITE_ORIGIN as string | undefined | null) ?? 'octopus:front:origin';
export const keyClientID: string = (import.meta.env.VITE_CLIENT_ID as string | undefined | null) ?? 'octopus:front:myclid';

// * global variables
export const showBottomLayer = ref(false);
export const aBottomLayerIsOpen = ref(false);
export const aModalIsOpen = ref(false);
export const globalHost = ref(import.meta.env.VITE_FAKE_HOST ?? window.location.origin);
export const showLayer = ref(false);
export const isLoading = ref(false);
export const isWaiting = ref(false);
type Snackbar = {
    icon: string[];
    color: string;
    message: string;
    timeout: number;
};
export const snackbar: Ref<Snackbar | null> = ref(null);
export const message: Ref<null | string> = ref(null);
export const confirmEmailRequired: Ref<boolean> = ref(false);
export const sideBar = ref(false);

// * global states
export const stateCurrentOrder: Ref<GetCurrentOrderQuery['Coaching']['me']['currentCoachingOrder']> = ref();

export const pgaOrders = ref<NonNullable<GetOrdersByCreditCardQuery['Coaching']['pga']>['ordersByCreditCard']>([]);
export const pgaCurrentOrder = ref<NonNullable<GetOrdersByCreditCardQuery['Coaching']['pga']>['ordersByCreditCard'][number]>();
export const pgaBearerToken = ref<string | null>(null);
export const cloudFrontUrl = 'https://d2wayiha1mwt35.cloudfront.net';

// * payment states
export const payLayer = ref(false);
export type BuyProduct = { productId: number; remoteId: number; sourceId: number; label: string; amount: number; countryCode: string; currencyCode: string; extFor: number; duration: string };
export const payEncode = (data: { productId: number; remoteId: number; sourceId: number }) => window.btoa(JSON.stringify(data));

function getBrowserType(): string {
    const navigatorAgent: string = navigator.userAgent;

    if (navigatorAgent.match(/opr\//i)) {
        return 'Opera';
    }
    if (navigatorAgent.match(/edg/i)) {
        return 'Edge';
    }
    if (navigatorAgent.match(/chrome|chromium|crios/i)) {
        return 'Chrome';
    }
    if (navigatorAgent.match(/firefox|fxios/i)) {
        return 'Firefox';
    }
    if (navigatorAgent.match(/safari/i)) {
        return 'Safari';
    }
    if (navigatorAgent.match(/trident/i)) {
        return 'IE';
    }
    return 'Unknown browser';
}

export function getScrollIntoViewOptions(options: ScrollIntoViewOptions): ScrollIntoViewOptions {
    // Option ScrollIntoViewOptions bug in chrome navigator
    if (getBrowserType() === 'Chrome') {
        options.behavior = 'auto';
    }
    return options;
}

const debounceTimer: Ref<number | undefined> = ref(undefined);
export const debounce = (callback: TimerHandler, time: number) => {
    window.clearTimeout(debounceTimer.value);
    debounceTimer.value = window.setTimeout(callback, time);
};

// write and read to localStorage can have sometimes delay, we try 2 times here with 500mq delay
export async function hasLocalStorageKey(key: string): Promise<boolean> {
    if (localStorage.getItem(key) === null) {
        await new Promise((resolve) => {
            setTimeout(resolve, 500);
        });
        if (localStorage.getItem(key) === null) {
            return false;
        }
    }
    return true;
}
